import './App.css';
import './css/font.css';
import {Routes, Route, NavLink} from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Catalogues from './components/Catalogues';
import ContactUs from './components/ContactUs';
import Distributors from './components/Distributors';
import Products from './components/Products';
import Home from './components/Home';
import logo_negro from './images/isologo_negro.png';

function App() {
  return (
    <div className="App">
     <div className="header-container">
      <div className="logo-container"></div>
      <div className='logo-container'>
        <img src={logo_negro} alt="" width={335} height={98} className='logo-header' />
      </div>
      <nav>
      <div className='menu-container'>
        <div className='menu-item'><NavLink to="home">Home</NavLink> |</div>
        <div className='menu-item'><NavLink to="about">About Us</NavLink> |</div>
        {
          /*
          <div className='menu-item'><NavLink to="products">Products</NavLink> |</div>*/
          /*<div className='menu-item'><NavLink to="distributors">Where to buy</NavLink> |</div>*/
          /*
          <div className='menu-item'><NavLink to="catalogues">Catalogue</NavLink> |</div>*/
        }
          <div className='menu-item'><NavLink to="contactus">Contact Us</NavLink></div>
           
      </div>
      </nav>
     </div>
     <div className="body-container">
        <Routes>
          <Route path='home' element={<Home />} />
          <Route path='about' element={<AboutUs />} />
          <Route path='catalogues' element={<Catalogues />} />
          <Route path='contactus' element={<ContactUs />} />
          <Route path='distributors' element={<Distributors />} />
          <Route path='products' element={<Products />} />
          <Route path='*' element={<Home />} />
        </Routes>
     </div>
    </div>
  );
}

export default App;
